<template>
  <div class="warp">
    <div class="left_check_warp">
      <el-form label-width="220rem"
               style="min-height:780rem; margin-right: 15rem;"
               :model="form"
               ref="formRef"
               :rules="rules">
        <el-row :gutter="10">
          <el-col :span="24">
            <img src="@/assets/leftcontroller/返回.png"
                 @click="cancel"
                 class="imgback"
                 title="返回"
                 alt="返回" />
            <el-form-item label="选择科目：">
              <div class="subject_warp">
                <span v-for="item in showList"
                      :class="{'subject_item':true,'isActive':form.subject_id == item.subject_id}"
                      @click="checkSubject(item.subject_id)"
                      :key="item.subject_id">{{item.subject_name}}</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="选择地区："
                          prop="paper_district">
              <div class="shade"
                   @click.stop="show('dir')"></div>
              <el-select style="width:100%"
                         v-model="form.paper_district"
                         placeholder="请选择地区">
                <el-option v-for="item in paperDir"
                           :key="item.paper_district_id"
                           :label="item.paper_district_desc"
                           :value="item.paper_district_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">

            <el-form-item label="题目来源："
                          prop="sourceId">
              <div class="shade"
                   @click.stop="show('source')"></div>
              <el-select style="width:100%;"
                         v-model="form.sourceId"
                         filterable
                         disabled
                         multiple
                         placeholder="请选择题目来源">
                <el-option v-for="item in sourceList"
                           :key="item.source_id"
                           :label="item.source_name"
                           :value="item.source_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="开始年份："
                          prop="startYear">
              <el-select style="width:100%"
                         v-model="form.startYear"
                         filterable
                         placeholder="请选择开始年份">
                <el-option v-for="item in year"
                           :key="item"
                           :label="item"
                           :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="截止年份："
                          prop="endYear">
              <el-select style="width:100%"
                         v-model="form.endYear"
                         filterable
                         placeholder="请选择截止年份">
                <el-option v-for="item in year"
                           :key="item"
                           :label="item"
                           :value="item">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

        </el-row>

        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="题目数量："
                          prop="number">
              <el-input placeholder="请输入题目数量"
                        type="number"
                        :max="30"
                        v-model="form.number"
                        style="width:100%" />
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="题目难度："
                          prop="difficulty">
              <div class="shade"
                   @click.stop="show('difficulty')"></div>
              <el-select style="width:100%"
                         v-model="form.difficulty"
                         multiple
                         disabled
                         placeholder="请选择题目难度">
                <el-option v-for="item in difficultList"
                           :key="item.id"
                           :label="item.text"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="题目类型："
                          prop="type">
              <div class="shade"
                   @click.stop="show('type')"></div>
              <el-select style="width:100%"
                         v-model="form.type"
                         multiple
                         disabled
                         placeholder="请选择题目类型">
                <el-option v-for="item in typeList"
                           :key="item.question_type_id"
                           :label="item.question_type_name"
                           :value="item.question_type_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12"
                  v-show="isShow">
            <!--  语文英语才显示 -->
            <el-form-item label="题目类别："
                          prop="category">
              <div class="shade"
                   @click.stop="show('category')"></div>
              <el-select style="width:100%;height:70rem"
                         v-model="form.category"
                         multiple
                         disabled
                         placeholder="请选择题目类别">
                <el-option v-for="item in categoryList"
                           :key="item.question_category_id"
                           :label="item.question_category_name"
                           :value="item.question_category_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <!-- 高一高二显示章节考点，高三显示考点考点 -->
            <el-form-item label="考查范围：">
              <el-radio-group v-model="isKnowledge"
                              @change="changeContentType">
                <!-- <el-radio :label="0"
                          v-if="userInfo.edu_grade_id==1">考点</el-radio> -->
                <el-radio :label="2"
                          v-if="showSubject">章节</el-radio>
                <el-radio :label="1">考点</el-radio>
                <el-radio :label="4">学科素养</el-radio>
                <el-radio v-show="showSubject"
                          :label="3">关键能力</el-radio>

              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- isKnowledge 0显示考点，1显示知识点 2章节-->
        <!-- <div class="line"></div> -->
        <div class="point_warp"
             v-if="isKnowledge==0">
          考点：
        </div>
        <el-row :gutter="10"
                v-show="isKnowledge==0">
          <el-col :span="12">
            <el-form-item label="一级条目："
                          prop="testCentreId">
              <div class="shade"
                   @click.stop="show('testCentreId')"></div>
              <el-select style="width:100%"
                         v-model="form.testCentreId"
                         disabled
                         multiple
                         placeholder="请选择一级条目">
                <el-option v-for="item in testCentre1"
                           :key="item.id"
                           :label="item.text"
                           :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-show="testCentre2.length != 0 && form.testCentreId.length != 0">
            <el-form-item label="二级条目："
                          prop="testCentreId2">
              <div class="shade"
                   @click.stop="checkTesting2"></div>
              <el-select style="width:100%"
                         v-model="form.testCentreId2"
                         disabled
                         multiple
                         placeholder="请选择二级条目">
                <el-option v-for="item in testCentre2"
                           :key="item.check_point_2_id"
                           :label="item.check_point_2_desc"
                           :value="item.check_point_2_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10"
                v-if="testCentre3.length != 0&& form.testCentreId.length != 0 && isKnowledge==0">
          <el-col :span="12">
            <el-form-item label="三级条目："
                          prop="testCentreId3">
              <div class="shade"
                   @click.stop="checkTesting3"></div>
              <el-select style="width:100%"
                         v-model="form.testCentreId3"
                         disabled
                         multiple
                         placeholder="请选择三级条目">
                <el-option v-for="item in testCentre3"
                           :key="item.check_point_3_id"
                           :label="item.check_point_3_desc"
                           :value="item.check_point_3_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="point_warp"
             v-if="isKnowledge==1">
          考点：
        </div>

        <el-row :gutter="10"
                v-if="isKnowledge==1">
          <el-col :span="12">
            <el-form-item label="一级条目："
                          prop="point">
              <div class="shade"
                   @click.stop="show('point')"></div>
              <el-select style="width:100%"
                         v-model="form.point"
                         disabled
                         multiple
                         filterable
                         placeholder="请选择一级条目">
                <el-option v-for="item in pointList"
                           :key="item.knowledge_id"
                           :label="item.knowledge_desc"
                           :value="item.knowledge_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-show="showLeavel2.length != 0">
            <el-form-item label="二级条目："
                          prop="point2">
              <div class="shade"
                   @click.stop="checkPoint2"></div>
              <el-select style="width:100%"
                         v-model="form.point2"
                         multiple
                         disabled
                         placeholder="请选择二级条目">
                <el-option v-for="item in showLeavel2"
                           :key="item.knowledge_id"
                           :label="item.knowledge_desc"
                           :value="item.knowledge_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10"
                v-if="showLeavel3.length != 0&&isKnowledge==1">
          <el-col :span="12">
            <el-form-item label="三级条目："
                          prop="point3">
              <div class="shade"
                   @click.stop="checkPoint3"></div>
              <el-select style="width:100%"
                         v-model="form.point3"
                         disabled
                         multiple
                         placeholder="请选择三级条目">
                <el-option v-for="item in showLeavel3"
                           :key="item.knowledge_id"
                           :label="item.knowledge_desc"
                           :value="item.knowledge_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <div class="point_warp"
             v-show="isKnowledge==2">
          章节：
        </div>

        <el-row :gutter="10"
                v-if="isKnowledge==2">
          <el-col :span="12">
            <el-form-item label="一级条目："
                          prop="chapter">
              <div class="shade"
                   @click.stop="show('chapter')"></div>
              <el-select style="width:100%"
                         v-model="form.chapter"
                         disabled
                         multiple
                         filterable
                         placeholder="请选择一级条目">
                <el-option v-for="item in chapterList"
                           :key="item.chapter_id"
                           :label="item.chapter_desc"
                           :value="item.chapter_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-show="showChapter2.length != 0&&isKnowledge==2">
            <el-form-item label="二级条目："
                          prop="chapter2">
              <div class="shade"
                   @click.stop="checkChapter2"></div>
              <el-select style="width:100%"
                         v-model="form.chapter2"
                         multiple
                         disabled
                         placeholder="请选择二级条目">
                <el-option v-for="item in showChapter2"
                           :key="item.chapter_id"
                           :label="item.chapter_desc"
                           :value="item.chapter_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12"
                  v-show="showChapter3.length != 0&&isKnowledge==2">
            <el-form-item label="三级条目："
                          prop="chapter3">
              <div class="shade"
                   @click.stop="checkChapter3"></div>
              <el-select style="width:100%"
                         v-model="form.chapter3"
                         disabled
                         multiple
                         placeholder="请选择三级条目">
                <el-option v-for="item in showChapter3"
                           :key="item.chapter_id"
                           :label="item.chapter_desc"
                           :value="item.chapter_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-show="showChapter4.length != 0&&isKnowledge==2">
            <el-form-item label="四级条目："
                          prop="chapter4">
              <div class="shade"
                   @click.stop="checkChapter4"></div>
              <el-select style="width:100%"
                         v-model="form.chapter4"
                         disabled
                         multiple
                         placeholder="请选择四级条目">
                <el-option v-for="item in showChapter4"
                           :key="item.chapter_id"
                           :label="item.chapter_desc"
                           :value="item.chapter_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="point_warp"
             v-show="isKnowledge==3">
          关键能力：
        </div>

        <el-row :gutter="10"
                v-if="isKnowledge==3">
          <el-col :span="12">
            <el-form-item label="一级条目："
                          prop="capability_ids">
              <div class="shade"
                   @click.stop="show('capability_ids')"></div>
              <el-select style="width:100%"
                         v-model="form.capability_ids"
                         disabled
                         multiple
                         filterable
                         placeholder="请选择一级条目">
                <el-option v-for="item in capabilityList"
                           :key="item.capability_id"
                           :label="item.capability_name"
                           :value="item.capability_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-show="showCapabilityLeavel2.length != 0">
            <el-form-item label="二级条目："
                          prop="capability_ids2">
              <div class="shade"
                   @click.stop="checkCapability2"></div>
              <el-select style="width:100%"
                         v-model="form.capability_ids2"
                         multiple
                         disabled
                         placeholder="请选择二级条目">
                <el-option v-for="item in showCapabilityLeavel2"
                           :key="item.capability_id"
                           :label="item.capability_name"
                           :value="item.capability_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="point_warp"
             v-show="isKnowledge == 4">
          学科素养：
        </div>

        <el-row :gutter="10"
                v-if="isKnowledge == 4">
          <el-col :span="12">
            <el-form-item label="一级条目："
                          prop="capability_ids">
              <div class="shade"
                   @click.stop="show('capability2_ids')"></div>
              <el-select style="width:100%"
                         v-model="form.capability_ids"
                         disabled
                         multiple
                         filterable
                         placeholder="请选择一级条目">
                <el-option v-for="item in capabilityList2"
                           :key="item.capability_id"
                           :label="item.capability_name"
                           :value="item.capability_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="12"
                  v-show="showCapability2Leavel2.length != 0">
            <el-form-item label="二级条目："
                          prop="capability_ids2">
              <div class="shade"
                   @click.stop="check2Capability2"></div>
              <el-select style="width:100%"
                         v-model="form.capability_ids2"
                         multiple
                         disabled
                         placeholder="请选择二级条目">
                <el-option v-for="item in showCapability2Leavel2"
                           :key="item.capability_id"
                           :label="item.capability_name"
                           :value="item.capability_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="explain_title">
        <span @click="handleShow">
          <img src="@/assets/yiwen.png"
               style="width:26rem;height:26rem"
               alt="">说明
        </span>

      </div>
      <div class="explain"
           v-if="ecplain">
        <p>1.请按顺序选择科目、地区、题目来源、年份；</p>
        <p>2.语文、英语两科可以按题目类别筛选；</p>
        <p>3.考点选择：考点星级表示试卷中考点的重要程度：五星表示重要性最高，一星表示重要性最低。细化到二级考点的题目可能会导致题目数量不足；</p>
      </div>
      <div class="btn_wrap">
        <div class="submit"
             @click="submit">
          提 交
        </div>
        <!-- <div class="cancel"
             @click="cancel">
          取消
        </div> -->
      </div>

    </div>
    <div class="right_point_warp"
         id="viewBox">
      <div class="dir_warp"
           v-if="right_is_active == 'dir'">
        <div :class="{'check_item':true,'is_check':item.paper_district_id == form.paper_district}"
             v-for="item in paperDir"
             @click="checkItem(item.paper_district_id,'paper_district')"
             :key="item.paper_district_id">
          {{item.paper_district_desc}}
        </div>
      </div>

      <div class="source_warp"
           v-if="right_is_active == 'source'">
        <div :class="{'check_item':true,'is_check': form.sourceId.findIndex(it=>item.source_id == it) > -1,'is_disabled': form.sourceId.findIndex(it=>it=='0')>-1}"
             v-for="item in sourceList"
             @click="checkItemArr(item.source_id,'sourceId')"
             :key="item.source_id">
          {{item.source_name}}
        </div>
      </div>

      <div class="difficulty_warp"
           v-if="right_is_active == 'difficulty'">
        <div :class="{'check_item':true,'is_check':form.difficulty.findIndex(it=>item.id == it) > -1}"
             v-for="item in difficultList"
             @click="checkItemArr(item.id,'difficulty')"
             :key="item.id">
          {{item.text}}
        </div>
      </div>

      <div class="type_warp"
           v-if="right_is_active == 'type'">
        <div :class="{'check_item':true,'is_check':form.type.findIndex(it=>item.question_type_id == it) > -1}"
             v-for="item in typeList"
             @click="checkItemArr(item.question_type_id,'type')"
             :key="item.question_type_id">
          {{item.question_type_name}}
        </div>
      </div>

      <div class="category"
           v-if="right_is_active == 'category'">
        <div :class="{'check_item':true,'is_check':form.category.findIndex(it=>item.question_category_id == it) > -1}"
             v-for="item in categoryList"
             @click="checkItemArr(item.question_category_id,'category')"
             :key="item.question_category_id">
          {{item.question_category_name}}
        </div>
      </div>

      <!-- 多选区域 -->
      <!-- 考点一级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'point'">
        <div :class="{'check_item':true,'is_check':form.point.findIndex(it=>item.knowledge_id == it) > -1}"
             v-for="item in pointList"
             @click="checkItemArr(item.knowledge_id,'point')"
             :key="item.knowledge_id">
          {{item.knowledge_desc}}
        </div>
      </div>

      <!-- 考点二级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'point2'">
        <div :class="{'check_item':true,'is_check':form.point2.indexOf(item.knowledge_id) > -1}"
             v-for="item in showLeavel2"
             @click="checkItemArr(item.knowledge_id,'point2')"
             :key="item.knowledge_id">
          {{item.knowledge_desc}}
        </div>
      </div>

      <!-- 考点三级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'point3'">
        <div :class="{'check_item':true,
        'is_check':form.point3.findIndex(it=>item.knowledge_id == it) > -1}"
             v-for="(item,index) in showLeavel3"
             @click="checkItemArr(item.knowledge_id,'point3')"
             :key="index">
          {{item.knowledge_desc}}
        </div>
      </div>

      <!-- 考点 一级条目 -->
      <div class="testCentrewarp"
           v-if="right_is_active == 'testCentreId'">
        <div :class="{'check_item':true,
        'is_check':form.testCentreId.findIndex(it=>item.id == it) > -1}"
             v-for="item in testCentre1"
             @click="checkItemArr(item.id,'testCentreId')"
             :key="item.id">
          {{item.text}}
        </div>
      </div>

      <!-- 考点 二级条目 -->
      <div class="testCentrewarp"
           v-if="right_is_active == 'testCentreId2'">
        <div :class="{'check_item':true,
        'is_check':form.testCentreId2.findIndex(it=>item.check_point_2_id == it) > -1}"
             v-for="item in testCentre2"
             @click="checkItemArr(item.check_point_2_id,'testCentreId2')"
             :key="item.check_point_2_id">
          {{item.check_point_2_desc}}
        </div>
      </div>
      <!-- 考点 三级条目 -->
      <div class="testCentrewarp"
           v-if="right_is_active == 'testCentreId3'">
        <div :class="{'check_item':true,
        'is_check':form.testCentreId3.findIndex(it=>item.check_point_3_id == it) > -1}"
             v-for="item in testCentre3"
             @click="checkItemArr(item.check_point_3_id,'testCentreId3')"
             :key="item.check_point_3_id">
          {{item.check_point_3_desc}}
        </div>
      </div>

      <!-- 章节一级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'chapter'">
        <div :class="{'check_item':true,'is_check':form.chapter.findIndex(it=>item.chapter_id == it) > -1}"
             v-for="item in chapterList"
             @click="checkItemArr(item.chapter_id,'chapter')"
             :key="item.chapter_id">
          {{item.chapter_desc}}
        </div>
      </div>

      <!-- 章节二级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'chapter2'">
        <div :class="{'check_item':true,'is_check':form.chapter2.indexOf(item.chapter_id) > -1}"
             v-for="item in showChapter2"
             @click="checkItemArr(item.chapter_id,'chapter2')"
             :key="item.chapter_id">
          {{item.chapter_desc}}
        </div>
      </div>

      <!-- 章节三级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'chapter3'">
        <div :class="{'check_item':true,
        'is_check':form.chapter3.findIndex(it=>item.chapter_id == it) > -1}"
             v-for="(item,index) in showChapter3"
             @click="checkItemArr(item.chapter_id,'chapter3')"
             :key="index">
          {{item.chapter_desc}}
        </div>
      </div>
      <!-- 章节四级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'chapter4'">
        <div :class="{'check_item':true,
        'is_check':form.chapter4.findIndex(it=>item.chapter_id == it) > -1}"
             v-for="(item,index) in showChapter4"
             @click="checkItemArr(item.chapter_id,'chapter4')"
             :key="index">
          {{item.chapter_desc}}
        </div>
      </div>
      <!-- 关键能力一级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'capability_ids'">
        <div :class="{'check_item':true,'is_check':form.capability_ids.findIndex(it=>item.capability_id == it) > -1}"
             v-for="item in capabilityList"
             @click="checkItemArr(item.capability_id,'capability_ids')"
             :key="item.capability_id">
          {{item.capability_name}}
        </div>
      </div>

      <!-- 关键能力二级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'capability_ids2'">
        <div :class="{'check_item':true,'is_check':form.capability_ids2.indexOf(item.capability_id) > -1}"
             v-for="item in showCapabilityLeavel2"
             @click="checkItemArr(item.capability_id,'capability_ids2')"
             :key="item.capability_id">
          {{item.capability_name}}
        </div>
      </div>
      <!-- 学科素养一级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'capability2_ids'">
        <div :class="{'check_item':true,'is_check':form.capability_ids.findIndex(it=>item.capability_id == it) > -1}"
             v-for="item in capabilityList2"
             @click="checkItemArr(item.capability_id,'capability_ids')"
             :key="item.capability_id">
          {{item.capability_name}}
        </div>
      </div>

      <!-- 学科素养二级条目 -->
      <div class="point_warp"
           v-if="right_is_active == 'capability2_ids2'">
        <div :class="{'check_item':true,'is_check':form.capability_ids2.indexOf(item.capability_id) > -1}"
             v-for="item in showCapability2Leavel2"
             @click="checkItemArr(item.capability_id,'capability_ids2')"
             :key="item.capability_id">
          {{item.capability_name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getStudentSubject } from '@/api/subject.js'
import { mapMutations, mapState } from 'vuex'
import { getCapability } from '@/api/capability.js'
export default {
  data () {
    var checkNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('题目数量不能为空'));
      }
      setTimeout(() => {

        if (value > 30) {
          callback(new Error('题目数量不能超过30'));
        } else {
          callback();
        }

      }, 1000);
    };
    return {
      userInfo: {},
      paperInfo: '',
      // subjectList: [],
      paperDir: [],
      sourceList: [],
      typeList: [],
      categoryList: [],
      categoryListFlat: [],
      //考点列表
      testCentre1: [],
      testCentre2: [],
      testCentre3: [],
      showSubject: false,
      difficultList: [],
      // 考点列表
      pointList: [],

      // 章节列表
      chapterList: [],
      chapterListFlat: [],
      //关键能力
      capabilityList: [],
      //学科素养
      capabilityList2: [],
      capabilityListFlat: [],
      capabilityList2Flat: [],

      form: {
        sourceId: [],
        subject_id: '',
        paper_district: '',
        startYear: null,
        endYear: null,
        number: 5,
        type: [],
        category: [],
        difficulty: [],
        capability_ids:[],
        capability_ids2:[],
        chapter: [],

        //考点
        testCentreId: [],
        testCentreId2: [],
        testCentreId3: [],
        //考点
        point: [],
        point2: [],
        point3: []
      },
      right_is_active: 'dir',
      ecplain: false,
      rules: {
        paper_district: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        sourceId: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        startYear: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        endYear: [
          { required: true, message: '请输入', trigger: 'change' },
        ],
        number: [
          { required: true, validator: checkNumber, trigger: 'change' },
        ],
        type: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        category: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        point: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        point2: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        point3: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
        chapter: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        capability_ids: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        difficulty: [
          { required: false, message: '请选择', trigger: 'change' },
        ],
      },
      isLoading: false,
      scrollTotal: 0,
      scrollPage: 1,
      difficultList: [],
      years: [
        2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029
      ],
      isKnowledge: 1, // 0显示考点，1显示知识点 ,2章节
      showList: []
    }
  },

  computed: {
    ...mapState(['subjectList']),
   
    year () {
      let nowYear = new Date().getFullYear()
      let arr = this.years.filter(item => {
        return item <= nowYear + 1
      })
      return arr
    },
    isShow () {
      if (!this.subjectList || this.subjectList.length == 0 || !this.form.subject_id) {
        return false
      }
      var subject = this.subjectList.find(item => item.subject_id == this.form.subject_id)
      if (subject.subject_name == '语文' || subject.subject_name == '英语') {
        return true
      }
      return false
    },

    //关键能力
    showCapabilityLeavel2 () {
      let arr = []
      this.capabilityListFlat.forEach(item => {
        if (this.form.capability_ids.indexOf(item.capability_id) != -1 && item.child) {
          arr = arr.concat(item.child)
        }
      });
      return arr
    },
    //学科素养
    showCapability2Leavel2 () {
      let arr = []
      // console.log('capabilityList2Flat', capabilityList2Flat)
      this.capabilityList2Flat.forEach(item => {
        if (this.form.capability_ids.indexOf(item.capability_id) != -1 && item.child) {
          arr = arr.concat(item.child)
        }
      });
      return arr
    },

    //考点
    showLeavel2 () {
      let arr = []
      this.categoryListFlat.forEach(item => {
        if (this.form.point.indexOf(item.knowledge_id) != -1 && item.child) {
          arr = arr.concat(item.child)
        }
      });
      return arr
    },
    showLeavel3 () {
      let arr = []
      this.categoryListFlat.forEach(item => {
        let idm = this.form.point2.indexOf(item.knowledge_id)
        if (idm != -1 && item.child) {
          arr = arr.concat(JSON.parse(JSON.stringify(item.child)))
        }
      });
      return arr
    },
    //章节
    showChapter2 () {
      let arr = []
      this.chapterListFlat.forEach(item => {
        if (this.form.chapter &&this.form.chapter.indexOf(item.chapter_id) != -1 && item.child) {
          arr = arr.concat(item.child)
        }
      });
      return arr
    },
    showChapter3 () {
      let arr = []
      this.chapterListFlat.forEach(item => {
        let idm = this.form.chapter2.indexOf(item.chapter_id)
        if (idm != -1 && item.child) {
          arr = arr.concat(JSON.parse(JSON.stringify(item.child)))
        }
      });
      return arr
    },
    showChapter4 () {
      let arr = []
      this.chapterListFlat.forEach(item => {
        let idm = this.form.chapter3.indexOf(item.chapter_id)
        if (idm != -1 && item.child) {
          arr = arr.concat(JSON.parse(JSON.stringify(item.child)))
        }
      });
      return arr
    },
  },
  watch: {
    'form.number' (newValue) {
      // if (newValue > 30) {
      //   this.$set(this.form, 'number', 30);
      // }
    },
    //考点一级条目变动
    'form.point': {
      handler (newValue, oldValue) {

        if (oldValue.length > 0) {
          this.form.point2 = []
        }

      },
      deep: true
    },
    //考点二级条目变动
    'form.point2': {
      handler (newValue, oldValue) {
        if (oldValue.length > 0) {
          this.form.point3 = []
        }
      },
      deep: true
    },
    //考点一级条目变动
    'form.testCentreId': {
      handler () {
        this.form.testCentreId2 = []
        //获取二级考点列表
        this.getTesting2()
      },
      deep: true
    },
    //考点二级条目变动
    'form.testCentreId2': {
      handler () {
        this.form.testCentreId3 = []
        //获取三级考点列表
        this.getTesting3()
      },
      deep: true
    },
    //章节一级条目变动
    'form.chapter': {
      handler () {
        this.form.chapter2 = []
      },
      deep: true
    },
    //章节二级条目变动
    'form.chapter2': {
      handler () {
        this.form.chapter3 = []
      },
      deep: true
    },
    //章节三级条目变动
    'form.chapter3': {
      handler () {
        this.form.chapter4 = []
      },
      deep: true
    },
    'isKnowledge': {
      handler (val) {
        if (val == 3) {
          this.showList = this.subjectList.filter(item => {
            if (item.subject_id != 7 && item.subject_id != 11) {
              return item
            }
          })
        } else {
          this.showList = this.subjectList;
        }

      },
      deep: true
    }
  },
  mounted () {

    const myDiv = document.getElementById('viewBox');

    myDiv.addEventListener('scroll', (event) => {
      if (!event) return;
      const scrollTop = Math.ceil(event.target.scrollTop); // 对 scrollTop 进行取整

      // 检查是否滚动到底部

      if (scrollTop + event.target.clientHeight >= event.target.scrollHeight && !this.isLoading && this.right_is_active == 'source') {
        if (this.sourceList.length < this.scrollTotal) {
          this.scrollPage += 1

          this.getSourceData(0, true);
        } else {
          this.$message.info("已显示全部题目来源")
        }

      }
    });




    this.userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
    // if (this.userInfo.edu_grade_id == 1) {
    //   this.isKnowledge = 0
    // } else {

    if (!this.showSubject) {

      this.isKnowledge = 1
    } else {

      this.isKnowledge = 2
    }
    

    // }
    //获取记录参数

    this.$http({
      url: '/api/v1/public/get_record',
      method: 'get',
      params: { module_id: 8 }
    }).then(res => {
      if (res.data.list && Object.keys(res.data.list).length > 0) {
        this.form = res.data.list
      }
      this.getSubjectList()
      this.getDefficultList()
    })


    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;


    if (currentMonth >= 1 && currentMonth <= 8) {
      // 1~8月份，开始年份默认本年度-1，截止年份默认本年度
      this.form.startYear = currentDate.getFullYear() - 1;
      this.form.endYear = currentDate.getFullYear();
    } else {
      // 9~12月份，开始年份默认本年度，截止年份默认本年度+1
      this.form.startYear = currentDate.getFullYear();
      this.form.endYear = currentDate.getFullYear() + 1;
    }
    if (this.form.subject_id == 7 || this.form.subject_id == 11) {
        this.showSubject = false
      } else {
        this.showSubject = true
      }


  },
  methods: {
   
    handleChange () {
     
      this.$refs.formRef.validate(valid => {
        console.log('valid', valid);
        if (!valid) {
          console.log('验证失败');
        } else {
          console.log('验证成功');
        }
      });

    },
    getSourceData (get_count, state) {
      if (get_count == 0) {
        this.isLoading = state;
      }
      console.log('钱', this.sourceList);
      this.$http({
        url: '/api/v1/public/get_source',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          paper_district: this.form.paper_district,
          get_count: get_count,
          limit: 20,
          page: this.scrollPage
        }
      }).then(res => {
        console.log('data', res.data.data);
        if (get_count == 1) {
          this.scrollTotal = res.data.data

        } else {
          console.log('后fffffffffff', res.data.data);
          this.sourceList = [...this.sourceList, ...res.data.data];
          console.log('后', this.sourceList);

          // this.form.sourceId = this.sourceList[0].id
          this.isLoading = false;
        }

      }).finally(() => {
        // 重置加载状态
        this.isLoading = false;
      });
    },
    changeContentType (value) {
      this.form.capability_ids = []
      this.form.capability_ids2 = []
      this.right_is_active = 'empty'
    },
    getDefficultList () {
      this.$http({
        url: '/api/v1/public/get_question_difficulty',
        method: 'get'
      }).then(res => {
        this.difficultList = res.data
      })
    },
    setInfo () {
      return this.paperInfo
    },
    getTesting2 () {
      this.testCentre2 = []
      let checkpoints = this.form.testCentreId.join(',')
      if (checkpoints.length == 0) {
        return
      }

      let arr = []
      this.testCentre1.map(item => {
        this.form.testCentreId.map(item1 => {
          if (item.id == item1) {
            arr.push(item.children)
          }
        })

      })
      arr.map(item => {

        this.testCentre2.push(...item)
      })

      // this.$http({
      //   url: '/api/v1/public/check_point2',
      //   method: 'get',
      //   params: {
      //     // checkpoints: this.form.testCentreId.join(',') + ',01204232'
      //     checkpoints: checkpoints
      //   }
      // }).then(res => {
      //   this.testCentre2 = res.data.list
      // })
    },
    getTesting3 () {
      this.testCentre3 = []
      let checkpoints = this.form.testCentreId2.join(',')
      if (checkpoints.length == 0) {
        return
      }

      let arr = []
      console.log('iiiiiii', this.testCentre2)
      console.log('testCentreId2testCentreId2', this.form.testCentreId2)
      this.testCentre2.map(item => {
        this.form.testCentreId2.map(item2 => {
          if (item.check_point_2_id == item2) {
            arr.push(item.children)
          }
        })

      })
      arr.map(item => {

        this.testCentre3.push(...item)
      })
      console.log('weq', this.testCentre3)


    },
    ...mapMutations(['SET_SUBJECT']),
    async getSubjectList () {
      if (this.subjectList.length == 0) {
        const { data } = await getStudentSubject()
        this.SET_SUBJECT(data.list)
      }
      // const { data } = await getStudentSubject()
      // this.subjectList = data.list
      // this.form.subject_id = res.data.list[0].subject_id
      if (this.form.subject_id == '') {
        this.form.subject_id = this.subjectList[0].subject_id
      }

      this.getDirList()
      this.getKnowledge()
      // if (this.userInfo.edu_grade_id != 1) {
      this.getChapter()
      // }
      setTimeout(() => {
        this.getCateAndtype()
      }, 1000)

      this.getCapabilityList()
      this.getCapabilityList2()
      //初始化显示
      this.showList = this.subjectList
    },
    checkSubject (id) {
      this.scrollPage = 1
      if (id == 7 || id == 11) {
        this.showSubject = false
      } else {
        this.showSubject = true
      }
      if (this.form.subject_id == id) return
      this.form.subject_id = id
      // this.$refs.formRef.resetFields()
      this.getCapabilityList2()
      this.getDirList()
      setTimeout(() => {
        this.getCateAndtype()
      }, 1000)

      this.getKnowledge()
      this.getCapabilityList()

      // if (this.userInfo.edu_grade_id != 1) {
      this.chapterList = []
      this.chapterListFlat = []
      this.getChapter()
      // }
      // 切换科目需要清空所关联的下拉框
      this.form.paper_district = []
      this.form.category = []
      this.form.chapter = []
      this.form.sourceId = []
      this.form.type = []
      this.form.point = []
      this.form.capability_ids = []
      this.form.capability_ids2 = []
      this.form.difficulty = []
      this.right_is_active = 'dir'
    },
    getDirList () {
      this.$http({
        url: '/api/v1/public/paper_districts',
        method: 'get',
        params: {
          subject_id: this.form.subject_id
        }
      }).then(res => {
        this.paperDir = res.data.list
        if (!this.form.paper_district) {
          this.form.paper_district = res.data.list[0].paper_district_id
        }

        this.getSourceList(0)
        this.getSourceData(1);
        // if (this.userInfo.edu_grade_id == 1) {
        //   this.getTesting()
        // }
      })
    },
    getSourceList (get_count) {
      this.$http({
        url: '/api/v1/public/get_source',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          paper_district: this.form.paper_district,
          get_count: get_count,
          limit: 20
        }
      }).then(res => {
        this.sourceList = res.data.data
        // this.form.sourceId = this.sourceList[0].id
      })
    },
    getCateAndtype () {
      this.$http({
        url: '/api/v1/public/paper_type_category',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          district_id: this.form.paper_district
        }
      }).then(res => {
        this.typeList = res.data.list.question_type
        this.categoryList = res.data.list.question_category
      })
    },
    // 数组扁平化
    flatList (list) {
      let allList = []
      for (let i = 0, len = list.length; i < len; i++) {
        allList.push(list[i])
        if (list[i].child && list[i].child.length > 0) {
          allList = allList.concat(this.flatList(list[i].child))
        }
      }
      return allList
    },
    async getCapabilityList () {

      let params = {
        subject_id: this.form.subject_id,
        capability_type_id: 1
      }
      const { data } = await getCapability(params)
      this.capabilityList = data.list
      this.capabilityListFlat = this.flatList(data.list)

    },
    async getCapabilityList2 () {

      let params = {
        subject_id: this.form.subject_id,
        capability_type_id: 2
      }
      const { data } = await getCapability(params)
      this.capabilityList2 = data.list
      this.capabilityList2Flat = this.flatList(data.list)

    },
    getKnowledge () {
      this.$http({
        url: '/api/v1/public/get_knowledge',
        method: 'get',
        params: {
          subject_id: this.form.subject_id
        }
      }).then(res => {
        this.pointList = res.data.list
        this.categoryListFlat = this.flatList(res.data.list)
      })
    },
    getTesting () {
      this.$http({
        url: '/api/v1/public/check_point',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
          district_id: this.form.paper_district
        }
      }).then(res => {
        this.testCentre1 = res.data.list
      })
    },

    getChapter () {
      this.$http({
        url: '/api/v1/public/get_grade_chapter',
        method: 'get',
        params: {
          subject_id: this.form.subject_id,
        }
      }).then(res => {
        this.chapterList = res.data.list
        this.chapterListFlat = this.flatList(res.data.list)
      })
    },
    //获取二级考点
    checkPoint2 () {
      if (!this.form.point.length) {
        return
      }
      this.right_is_active = 'point2'
    },
    //获取二级能力
    checkCapability2 () {
      if (!this.form.capability_ids.length) {
        return
      }
      this.right_is_active = 'capability_ids2'
    },
    //获取二级学科
    check2Capability2 () {
      if (!this.form.capability_ids.length) {
        return
      }
      this.right_is_active = 'capability2_ids2'
    },
    //三级考点
    checkPoint3 () {
      if (!this.form.point2.length) {
        return
      }
      this.right_is_active = 'point3'
    },
    //获取二级章节
    checkChapter2 () {
      if (!this.form.chapter.length) {
        return
      }
      this.right_is_active = 'chapter2'
    },
    //三级章节
    checkChapter3 () {
      if (!this.form.chapter2.length) {
        return
      }
      this.right_is_active = 'chapter3'
    },
    //四级章节
    checkChapter4 () {
      if (!this.form.chapter3.length) {
        return
      }
      this.right_is_active = 'chapter4'
    },
    getList (str) {
      this.right_is_active = str
    },
    //二级考点
    checkTesting2 () {
      if (!this.form.testCentreId.length) {
        return
      }
      this.right_is_active = 'testCentreId2'
    },
    //三级考点
    checkTesting3 () {
      if (!this.form.testCentreId.length) {
        return
      }
      this.right_is_active = 'testCentreId3'
    },
    show (str) {
      this.scrollPage = 1
      this.right_is_active = str
    },
    // 右侧为多选的时候会触发这个方法
    checkItemArr (id, name) {


      let index = this.form[name].indexOf(id)
      if (index != -1) {
        
        this.form[name].splice(index, 1)
      } else if (this.form[name].length == 5 && this.right_is_active !== 'source') {
     
        this.$message.warning("最多能够选择5个！")
        return
      } else {
        this.form[name].push(id)
        this.handleChange()

      }
      if (name == 'sourceId' && this.form[name].includes(0)) {
       
        this.form[name] = [0]
      }
    },
    checkItem (id, name) {
      this.form[name] = id
      /*
        科目变，地区变
      */
      // 地区改变的时候，获取来源，类型类别
      if (this.right_is_active == 'dir') {
        this.form.sourceId = []
        this.form.category = []
        this.form.type = []
        this.getSourceList(0)
        this.getSourceData(1);
        //获取考点，考点跟着地区科目变
        // if (this.userInfo.edu_grade_id == 1) {
        //   this.getTesting()
        // }

        this.getCateAndtype()
      }
    },
    submit () {
      let knowledge_ids = this.form.point3
      if (knowledge_ids.length == 0) {
        if (this.form.point2.length == 0) {
          knowledge_ids = this.form.point
        } else {
          knowledge_ids = this.form.point2
        }
      }
      let capability_ids = []

      if (this.form.capability_ids2.length > 0) {
        capability_ids = this.form.capability_ids2

      } else {
        capability_ids = this.form.capability_ids
      }

      let chapter_ids = this.form.chapter4
      if (!chapter_ids || chapter_ids.length == 0) {
        chapter_ids = this.form.chapter3
        if (!chapter_ids || chapter_ids.length == 0) {
          chapter_ids = this.form.chapter2
          if (!chapter_ids || chapter_ids.length == 0) {
            chapter_ids = this.form.chapter
          }
        }
      }
      let checkpoints = this.form.testCentreId
      let checkpoints2 = this.form.testCentreId2
      let checkpoints3 = this.form.testCentreId3

      this.$refs.formRef.validate(val => {
        if (!val) return
        let form = {
          app_id: 4,
          questionSubject: this.form.subject_id,
          questionDistrict: this.form.paper_district,
          source_id: this.form.sourceId.includes(0) ? [] : this.form.sourceId,
          questionType: this.form.type,
          questionCategory: this.form.category,
          questionYearBegin: this.form.startYear,
          questionYearEnd: this.form.endYear,
          // checkpoints,
          // checkpoints2,
          // knowledge_ids,
          questionNumber: this.form.number,
          question_difficulty: this.form.difficulty,
          // template_id: 64,
          capability_ids
        }
        let url
        // 高三
        // if (this.userInfo.edu_grade_id == 1) {
        //   url = "/api/v1/free/prepare_examination_question"
        // } else {
        //   // 高一高二
        url = "/api/v1/free/free_question"
        // }
        if (this.isKnowledge == 1) {
          form.knowledge_ids = knowledge_ids
        }
        else if (this.isKnowledge == 2) {
          form.chapter_ids = chapter_ids
        }

        this.$http({
          url: url,
          method: 'POST',
          data: form
        }).then(res => {
          this.user_paper_id = res.data.list.user_paper_id
          this.$router.push('/doPaper?type=2&form=' + JSON.stringify({ user_paper_id: this.user_paper_id }))
          this.$message.success('成功')
          // window.injectedObject.startTrain(JSON.stringify(res))
        })
        this.form.module_id = 8
        //记录参数
        this.$http({
          url: '/api/v1/public/set_record',
          method: 'POST',
          data: this.form
        }).then(res => {

        })
      })


    },
    cancel () {
      this.$router.go(-1)
    },
    handleShow () {
      this.ecplain = !this.ecplain
    },
  }
}
</script>

<style lang="scss" scoped>
.warp {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(255, 255, 255, 1);
  font-size: 16rem;
  .left_check_warp {
    flex: 1;
    margin-top: 18rem;
    padding-left: 15px;
    overflow-x: hidden !important;
    scrollbar-gutter: stable;
    .imgback {
      position: absolute;
      width: 50rem;
      height: 50rem;
      top: 12rem;
      left: 5rem;
      cursor: pointer;
    }
    .subject_warp {
      overflow-x: scroll;
      white-space: nowrap;
      .subject_item {
        color: #333333;
        font-weight: 500;
        font-size: 16rem;
        background: rgba(246, 246, 246, 1);
        margin-right: 15rem;
        // width: 130rem;
        padding: 0 30rem;
        height: 60rem;
        border-radius: 10rem;
        font-size: 28rem;
        display: inline-block;
        text-align: center;
        line-height: 60rem;
        cursor: pointer;
      }
    }
    .point_warp {
      font-weight: bold;
      color: #2196f3;
      margin-top: 15rem;
      text-align: left;
      text-indent: 38rem;
      font-size: 28rem;
    }
    .explain_title {
      font-size: 28rem;
      margin-left: 38rem;
      span {
        display: flex;
        align-items: center;
      }
    }
    .explain {
      background: rgba(246, 246, 246, 1);
      font-size: 24rem;
      padding: 12rem 0 10rem 20rem;
      font-weight: 500;
      line-height: 24rem;
      margin-bottom: 30rem;
      p {
        line-height: 34rem;
      }
    }
    .btn_wrap {
      display: flex;
      .submit,
      .cancel {
        width: 48%;
        height: 88rem;
        border-radius: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 32rem;
        margin: 15rem 0;
        cursor: pointer;
      }
      .submit {
        // width: 610rem;
        background: #2196f3;
        margin-left: 10rem;

        color: #ffffff;
        &:hover {
          background: rgb(91, 179, 247);
        }
      }
      .cancel {
        // width: 610rem;
        background: #fff;
        color: #2196f3;
        border: 1px solid #2196f3;
        margin-left: 20rem;
        &:hover {
          background: #e6f4ff;
        }
      }
    }
  }
  .right_point_warp {
    height: 100vh;
    width: 580rem;
    background: rgba(235, 243, 255, 1);
    height: 100%;
    text-indent: 1em;
    overflow-y: scroll;
    line-height: 80rem;
    font-size: 28rem;
    .check_item {
      background: #ebf3ff;
      height: 46rem;
      line-height: 46rem;
      padding: 17rem 8rem;
      color: #343434;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border-bottom: 1px solid #f6fafb;
      cursor: pointer;
    }
  }
}
.line {
  width: 99%;
  height: 1rem;
  border-bottom: 1rem solid rgba(204, 204, 204, 1);
  margin-bottom: 15rem;
  margin-left: 15rem;
}
.isActive {
  background: #2196f3 !important;
  color: #ffffff !important;
}
::v-deep .el-form-item__label {
  font-size: 16rem;
  font-weight: 500;
  color: #333333;
}
::v-deep .el-radio__label {
  font-size: 26rem;
  line-height: 70rem;
  color: #333333;
}
::v-deep .el-radio__inner {
  width: 20rem;
  height: 20rem;
  &::after {
    width: 7rem;
    height: 7rem;
  }
}
::v-deep .el-radio__input {
  line-height: 40rem;
}

.is_check {
  background: rgba(108, 185, 245, 1) !important;
  font-weight: 500;
  color: white !important;
  border-color: #a1d6ff !important;
  white-space: normal !important;
  height: auto !important;
  min-height: 46rem;
}
.is_disabled {
  // background: rgb(197, 197, 197) !important;
  // font-weight: 500;
  // color: white !important;
  // border-color: #a1d6ff !important;
  // white-space: normal !important;
  // height: auto !important;
  // min-height: 46rem;
  cursor: not-allowed !important;
}

::v-deep .el-input.is-disabled .el-input__inner {
  background: #ffffff;
}

::v-deep .el-form-item__label {
  font-size: 28rem;
  line-height: 70rem;
}
::v-deep .el-input__inner {
  height: 70rem;
  font-size: 28rem;
  overflow: hidden; // 溢出部分隐藏
  white-space: nowrap; // 文字不换行
  text-overflow: ellipsis; // 显示省略号
}
::v-deep .el-input.is-disabled .el-input__inner {
  height: 70rem !important;
}
::v-deep .el-select-dropdown__item {
  font-size: 28rem !important;
}

::v-deep .el-select__tags-text {
  font-size: 28rem;
}

::v-deep .el-select__tags {
  overflow: hidden; // 溢出部分隐藏
  white-space: nowrap; // 文字不换行
  text-overflow: ellipsis; // 显示省略号
  height: 90rem !important;
  flex-wrap: nowrap;
  span {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
}

::v-deep .el-tag {
  background: none;
}
::v-deep .el-tag.el-tag--info {
  border: none;
}
.el-input__inner {
  &:hover {
    cursor: pointer !important;
  }
}
.el-form-item__content {
  position: relative;
}
.shade {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1999;
  cursor: pointer;
}
</style>